@import "../../../scss/general_styling";

.LoadingIcon {
  display: flex;
  justify-content: center;
  margin-bottom: 2em;
  width: 100%;
  .ant-spin {
    color: var(--gold-dark);
  }
}
.LoadingIcon-fullpage {
  background: var(--gradient-theme-5);
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .ant-spin {
    color: var(--gold-dark);
  }
}