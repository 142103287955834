@import "../../../scss/general_styling";

.OperationSelector {
  width: 100%;

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn .custom-tab.tab-buy {
    height: -webkit-fill-available;
    border-radius: 30px;
    color: $white;
    background: var(--gold-dark);
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn .custom-tab.tab-sell {
    height: -webkit-fill-available;
    border-radius: 30px;
    color: $white;
    background: var(--gold-dark);
  }
  .ant-tabs-tab:hover {
    color: $white;
  }
  .ant-tabs-tab.ant-tabs-tab-disabled {
    color: $grey-5;
  }
}