@import "./scss/_general_styling.scss";

body {
  font-family: "Montserrat", sans-serif;
}

iframe {
  display: none !important;
}

.logo {
  display: flex;
  align-items: center;
  margin-right: 2em;
  max-width: 180px;

  img {
    width: 50px;
  }
}

.site-layout-content {
  padding: 2em 0;
}

.ant-layout {
  background: var(--gradient-theme-5);
  padding-top: 2em;
  height: 100vh;
  overflow-y: auto;
}

.ant-layout-content {
  padding: 0 3em;
}

.ant-layout-header {
  display: flex;
  background: transparent;
  height: 40px;
  line-height: 40px;
  justify-content: space-between;
  padding: 0 3em;

  .WalletConfig {
    display: flex;
  }

  &.header-desktop {
    display: flex;
  }
}
.header-mobile {
  display: none;
}

.ant-layout-footer {
  background: transparent;
  color: $grey-5;
}

.ant-menu-horizontal {
  border-bottom: none;
  width: 100%;
}

.ant-menu {
  background: transparent;
}

.ant-dropdown-menu-item-selected {
  background: #222433;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected {
  color: white;
}

.ant-layout-header .ant-menu-item-disabled a {
  color: $grey-5 !important;
}
.ant-dropdown-menu-item-disabled a {
  color: $grey-5 !important;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected::after {
  border-bottom: 2px solid var(--gold-dark);
}

.ant-menu-horizontal>.ant-menu-item a {
  color: white;
}

.ant-menu-horizontal>.ant-menu-item a:hover,
.ant-menu-horizontal>.ant-menu-item a:active,
.ant-menu-horizontal>.ant-menu-item a:focus,
.ant-menu-horizontal>.ant-menu-item-selected a {
  color: var(--gold-dark);
}

.ant-menu-horizontal>.ant-menu-submenu>.ant-menu-submenu-title {
  color: white;
}

.ant-tabs>.ant-tabs-nav,
.ant-tabs>div>.ant-tabs-nav {
  background: $black-2;
  border-radius: 40px;
}

.ant-tabs-top>.ant-tabs-nav::before,
.ant-tabs-bottom>.ant-tabs-nav::before,
.ant-tabs-top>div>.ant-tabs-nav::before,
.ant-tabs-bottom>div>.ant-tabs-nav::before {
  border-bottom: none;
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-list {
  width: 100%;
}

.ant-tabs-tab {
  width: 50%;
  height: 45px;
  display: block;
  text-align: center;
  line-height: 28px;
  transition: 0.5s;
  color: $white;
  padding: 8px;
}

.ant-tabs-ink-bar {
  display: none;
}

.ant-input-affix-wrapper-lg {
  padding: 10px 14px;
  background: $black-2;
  border: none;
  @include primary-text($body, $grey-4, 400);
  border-radius: 8px;
}

.ant-input-affix-wrapper>input.ant-input {
  background: transparent;
  @include number-text($numbers, $white, 400);
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: none;
  border: none;
  outline: none;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border: none;
  outline: none;
}

.ant-table {
  background: transparent;
  color: $white;
  font-family: "Montserrat", sans-serif;
}

.ant-table-thead>tr>th {
  background: transparent;
  border-bottom: transparent;
  color: $grey-5;
  font-size: $label;
  text-transform: uppercase;
}

.ant-table-cell .ant-table-cell-row-hover {
  background: transparent;
}

.ant-table-tbody>tr.ant-table-row:hover>td,
.ant-table-tbody>tr>td.ant-table-cell-row-hover {
  background: transparent;
}

.ant-table-tbody>tr>td {
  border-bottom: transparent;
}

.ant-tag {
  border-radius: 20px;
  font-weight: 700;
  padding: 0 10px;
}

.ant-tag-has-color,
.ant-tag-has-color a,
.ant-tag-has-color a:hover,
.ant-tag-has-color .anticon-close,
.ant-tag-has-color .anticon-close:hover {
  color: $black-1;
}

.ant-popover-arrow-content,
.ant-popover-arrow-content::before {
  background: $black-2;
}
.ant-popover-inner-content {
  background: $black-2;
  display: flex;
  flex-direction: column;
  gap: 8px;
  button.CustomButton {
    width: 100%;
    justify-content: start;
    margin: 0 !important;

  }

}

@media only screen and (max-width: 425px) {
  .ant-layout-header,
  .ant-layout-content {
    padding: 0 2em;
  }

  .header-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2em;
    color: white;
  }
  .menu-mobile-dropdown {
    width: 300px;
    background: rgba(0, 0, 0, 0.9);
    top: 70px !important;
    overflow: hidden;
    .ant-dropdown-menu {
      padding-top: 20px;
      background: var(--gradient-theme-5);
      border: 1px solid #5f606e;
      .ant-dropdown-menu-item {
        color: $white;
        padding: 20px 2em;
        text-align: center;
        &:hover {
          background: #2f3037;
        }
      }
      button {
        margin: 2em auto;
      }
      .menu-mobile-icon {
        font-size: 40px;
      }
    }
  }
  .ant-layout-header {
    flex-wrap: wrap;
    height: auto;

    .logo {
      justify-content: center;
      margin-right: 0;
    }

    .ant-menu-horizontal {
      width: min-content;
    }

    &.header-desktop {
      display: none;
    }
    .WalletConfig {
      margin-top: 1.5em;
      justify-content: center;
      width: 100%;
    }
  }
}
.ant-message {
  z-index: 1100;
}

.ant-message-info .anticon,
.ant-message-loading .anticon {
  color: #d07d2c;
}

.ant-message-notice-content {
  background: #ffffff;
  color: #100901;
  font-weight: 600;
}